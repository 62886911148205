import { load } from "./utilities";
import React from "react";
import { Redirect } from "react-router-dom";

export const ROUTES = {
  ROOT: {
    path: "/",
    exact: true,
    render: () => <Redirect to="/login" />,
  },
  ADMIN: {
    path: "/admin",
    component: load("Admin"),
  },
  VIEWDASHBOARD: {
    path: "/admin",
    exact: true,
    component: load("dashboard/Dashboard"),
  },

  MANAGEUSERS: {
    path: "/admin/users",
    exact: true,
    component: load("users/Users"),
  },
  EDITUSERS: {
    path: "/admin/users/edit/:id",
    exact: true,
    component: load("users/EditUser"),
  },
  MANAGEADMIN: {
    path: "/admin/manage-admin",
    exact: true,
    component: load("users/ManageAdmin"),
  },

  MANAGEPANUSER: {
    path: "/admin/manage-pan-user",
    exact: true,
    component: load("users/ManagePanUser"),
  },

  MANAGECATEGORIES: {
    path: "/admin/categories",
    exact: true,
    component: load("categories/Categories"),
  },

  MANAGEREQUESTS: {
    path: "/admin/requests",
    exact: true,
    component: load("requests/Requests"),
  },

  MANAGETRANSACTIONS: {
    path: "/admin/transactions",
    exact: true,
    component: load("transactions/Transactions"),
  },

  MANAGE_REJECTED_REQUESTS: {
    path: "/admin/requests/rejected",
    exact: true,
    component: load("requests/RejectedRequests"),
  },

  MANAGETOPICS: {
    path: "/admin/topics",
    exact: true,
    component: load("topics/Topics"),
  },

  MANAGEREASON: {
    path: "/admin/reasons",
    exact: true,
    component: load("reasons/Reasons"),
  },

  MANAGEREJECTREASON: {
    path: "/admin/reject-reasons",
    exact: true,
    component: load("rejectReasons/RejectReasons"),
  },

  VIEW_INSTANT_CLASS: {
    path: "/admin/class/instant",
    exact: true,
    component: load("class/Instant"),
  },
  VIEW_SCHEDULE_CLASS: {
    path: "/admin/class/schedule",
    exact: true,
    component: load("class/Schedule"),
  },
  VIEW_PLAN_DETAILS: {
    path: "/admin/plan/details",
    exact: true,
    component: load("plan/PlanDetails"),
  },
  ADD_PLAN_DETAILS: {
    path: "/admin/plan/add",
    exact: true,
    component: load("plan/AddPlan"),
  },
  VIEWBOOKINGS: {
    path: "/admin/bookings",
    exact: true,
    component: load("bookings/Bookings"),
  },
  VIEWREFUNDS: {
    path: "/admin/refunds",
    exact: true,
    component: load("refunds/Refunds"),
  },
  VIEWREPORTS: {
    path: "/admin/report",
    exact: true,
    component: load("report/Report"),
  },
  VIEWPAYOUTS: {
    path: "/admin/payouts",
    exact: true,
    component: load("payouts/Payouts"),
  },
  VIEWSETTINGS: {
    path: "/admin/settings",
    exact: true,
    component: load("settings/Settings"),
  },

  LOGOUT: {
    path: "/logout",
    exact: true,
    component: load("Logout"),
  },
  LOGIN: {
    path: "/login",
    exact: true,
    component: load("Login"),
  },
  RESETPASSWORD: {
    path: '/admin/change-password/:resetToken',
    exact: true,
    component: load('ResetPassword')
  },
  CHANGEPASSWORD: {
    path: "/admin/changePassword",
    exact: true,
    component: load("changePassword/ChangePassword"),
  },
  NOTFOUND: {
    component: load("Sidebar"),
  },
  MANAGECOUPON: {
    path: "/admin/coupon-management",
    exact: true,
    component: load("couponManagement/CouponManagement")
  },
  MANAGEDEMOVIDEO: {
    path: "/admin/demo-video",
    exact: true,
    component: load("demoVideo/DemoVideo"),
  },
  WHATSAPPMESSAGELIST: {
    path: "/admin/whatsappMessageList",
    exact: true,
    component: load("whatsappMessage/WhatsappMessageList"),
  },
  BANNERIMAGE: {
    path: "/admin/banner-image",
    exact: true,
    component: load("bannerImage/BannerImage"),
  },
  DENOMINATION: {
    path: "/admin/denomination",
    exact: true,
    component: load("denomination/Denomination"),
  },
  DIRECTWHATSAPPMESSAGE: {
    path: "/admin/whatsappMessage",
    exact: true,
    component: load("whatsappMessage/WhatsappMessage"),
  },
  MANAGE_TEACHER_INSTITUTE_USERS: {
    path: "/admin/teacher-institute-users",
    exact: true,
    component: load("teacherInstituteUser/TeacherInstituteUser"),
  },
  VIEW_TEACHER_INSTITUTE_USERS: {
    path: "/admin/view-user/:id",
    exact: true,
    component: load("teacherInstituteUser/ViewUser"),
  },
  VIEW_SUBSCRIPTION:{
    path: "/admin/view-subscription",
    exact: true,
    component: load("subscription/SubscriptionList"),
  },
  WAITLIST: {
    path: "/admin/waitlist",
    exact: true,
    component: load("waitlist/WaitList"),
  }
};
